<template>
  <div class="case-conent">
    <div class="hd">知识内容测评</div>
    <!--学历案日志列表-->
    <div class="table-content-search">
      <div class="search">
        <div class="fromItem">
          <el-select v-model="ruleForm.subjectId" @change="subjectChange" @clear="subjectClear()" clearable placeholder="学科选择">
            <el-option
                v-for="item in SubjectList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="table">
        <el-table
            border
            :header-cell-style="{background:'#D1EEFE',color:'#333'}"
            :data="listData"
        >
          <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
          <el-table-column prop="bookName" label="图书名称" header-align="center"></el-table-column>
          <el-table-column prop="subjectName" label="学科" header-align="center"></el-table-column>
          <el-table-column prop="exercises" label="练习总数" header-align="center"></el-table-column>

          <el-table-column prop="" label="操作" header-align="center" width="180">
            <template #default="scope">
              <el-button @click="tosKnowledgeContent(scope)" type="primary" size="mini" plain>查看</el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div class="page">
        <div class="pag-box" v-if="listData !=0">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>

    </div>
  </div>

</template>


<script>
import { mapState } from 'vuex'
import {qfindScreenSubjectObj,queryContentBookListObj} from '@/api/evaluation'
import {
  findScreenSubjectObj
} from '@/api/courseware'
export default {
  name: '',
  data() {
    return {
      clickFlag:1,
      SubjectList: [],
      pageSize:null,
      pageCurrent:null,
      total:null,
      ruleForm:{
        subjectId:null
      },
      listData:[

      ],

    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.stu = this.$route.query.stu;
    this.findScreenSubject()
    this.queryContentBookList()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  computed:{
    ...mapState([

    ]),
  },
  methods: {
    subjectObj() {
      this.ruleForm.subjectId = this.SubjectList[0].id
    },
    //查询筛选的学科列表
    findScreenSubject() {
      findScreenSubjectObj().then((res) => { // eslint-disable-line no-unused-vars
        this.SubjectList = res.data
      })
    },

    // 学科
    subjectChange($event){
      sessionStorage.setItem('subjectId',$event)
      this.queryContentBookList()
    },
    // 清除学科
    subjectClear() {
      this.evaluation.subject = ''
      sessionStorage.removeItem('subjectId');
      this.queryContentBookList()
    },



    //查询列表
    queryContentBookList() {
      let params = {
        subjectId:this.ruleForm.subjectId,
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      queryContentBookListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.listData = res.data.data.records
          this.pageSize = res.data.data.size;
          this.currentPage = res.data.data.current;
          this.total = res.data.data.total
        }else {
          this.listData = ''
          this.total = ''
        }

      })
    },
    //充值订单列表分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.queryContentBookList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.queryContentBookList();
    },
    // 去详情
    tosKnowledgeContent(scope) {
      this.$router.push({
        path: "/mainb/knowledgeContent",
        query: {
          EducationCase:this.EducationCase,
          bookId:scope.row.id,
          bookName:scope.row.bookName,
          clickFlag:2,
          stu:this.stu
        }
      });
    },

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }

  .table-content-search {
    width: 96%;
    height: 93%;
    margin: 10px auto;
    .search {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

      }
    }
    .table {
      height: 60%;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
