//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
//
export function qfindScreenSubjectObj(obj) {
    return request({
        url: '/search/parameters/findScreenSubject',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询知识内容评测图书列表：
export function queryContentBookListObj(obj) {
    return request({
        url: '/study/students/queryContentBookList',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询知识内容评测练习列表：
export function yContentExerciseListObj(obj) {
    return request({
        url: `/study/students/queryContentExerciseList/${obj.bookId}`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询知识内容评测练习列表：
export function tKnowledgeListObj(obj) {
    return request({
        url: `/study/students/queryContentKnowledgeList/${obj.paperId}`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询知识内容评测批改结果：
export function tKnowContentCorrectsObj(obj) {
    return request({
        url: `/study/students/queryContentCorrects/${obj.paperId}`,    // url = base url + request url
        method: 'get',
        data: obj,
    })
}

