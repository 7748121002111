//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
// 查询筛选的年份列表：
export function findScreenYearObj(obj) {
    return request({
        url: '/search/parameters/findScreenYear',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的学科列表：
export function findScreenSubjectObj(obj) {
    return request({
        url: '/search/parameters/findScreenSubject',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的年级列表：
export function findScreenGradeObj(obj) {
    return request({
        url: '/search/parameters/findScreenGrade',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的学期列表：
export function findScreenSemesterObj(obj) {
    return request({
        url: '/search/parameters/findScreenSemester',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的学段列表：
export function findScreenStudySectionObj(obj) {
    return request({
        url: '/search/parameters/findScreenStudySection',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的教材版本列表：
export function findScreenTextbookEditionObj(obj) {
    return request({
        url: '/search/parameters/findScreenTextbookEdition',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询筛选的系列：
export function findScreenSeriesObj(obj) {
    return request({
        url: '/search/parameters/findScreenBrand',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询图书列表-试用：
export function getBooksObj(obj) {
    return request({
        url: '/search/tourists/getBooks',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询图书列表-学生：
export function getStuBooksObj(obj) {
    return request({
        url: '/study/students/getBooks',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}


